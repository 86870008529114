<template>
  <v-dialog v-model="dialog" persistent width="550">
    <v-card :loading="loading" class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Make order'" @close="dialog = false" />
      <v-card-text>
        <div style="color: yellow">Your quota (used/total): {{ quota.quota_used }} / {{ quota.quota_sqkm }} (sqkm)</div>
        <v-radio-group v-model="downloadType" class="my-2" dense hide-details row>
          <v-radio label="Scene" value="scene"></v-radio>
          <v-radio label="Clip" value="clip"></v-radio>
        </v-radio-group>
        <v-select
          v-model="data.productBundle"
          :items="productBundles"
          dense
          item-disabled="disable"
          item-text="name"
          item-value="key"
          label="Product Bundle"
          outlined
          placeholder="Product Bundle"
        ></v-select>
        <v-layout class="pt-5">
          <v-spacer />
          <v-btn :loading="loading" color="primary" rounded @click="submitData">
            Make order
          </v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import PlanetApi from '@/utils/planet/planetApi'
import BUNDLES from '@/constants/bundles'
import ConvertDate from '@/utils/convertDate'

export default {
  components: { DialogHeader },
  data() {
    return {
      quota: {},
      bundles: [...BUNDLES],
      dialog: false,
      loading: false,
      image: undefined,
      downloadType: 'scene',
      geometry: undefined,
      data: {
        itemIds: [],
        type: undefined,
        productBundle: undefined,
        geometry: undefined,
      },
    }
  },
  watch: {
    downloadType(val) {
      this.bundles.forEach(bundle => {
        bundle.disable = bundle.key.includes('basic_') && val === 'clip'
      })
    },
  },
  computed: {
    productBundles() {
      if (!this.image) return []
      let bundleAvailable = []
      let bundleByType = this.bundles.filter(val => val.assets[this.image.properties.item_type])
      bundleByType.forEach(val => {
        let check = true
        val.assets[this.image.properties.item_type].forEach(asset => {
          if (!this.image._permissions.toString().includes(asset)) check = false
        })
        if (check) bundleAvailable.push(val)
      })
      return bundleAvailable
    },
  },
  methods: {
    openDialog(image, geometry) {
      this.image = image
      this.geometry = geometry
      this.getQuota()
      this.dialog = true
    },
    async getQuota() {
      try {
        this.loading = true
        const res = await PlanetApi.getQuota()
        this.quota = res[0]
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async submitData() {
      try {
        this.loading = true
        this.data.itemIds = this.image.ids ? this.image.ids : [this.image.id]
        this.data.type = this.image.properties.item_type
        if (this.downloadType === 'scene') this.data.geometry = this.geometry
        else this.data.geometry = undefined
        this.data.name =
          this.image.properties.item_type + '_' + ConvertDate.dateTimeFormatted(this.image.properties.acquired)
        await PlanetApi.order(this.data)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
      } catch (e) {
        this.$store.commit('message/SHOW_ERROR', e.message)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
