<template>
  <v-layout class="fill-height overflow-y-hidden" column>
    <MapView />
  </v-layout>
</template>
<script>
import MapView from '@/views/map-overview/MapView.vue'
import AOI from '@/views/aoi/AOI.vue'

export default {
  components: { AOI, MapView },
  data() {
    return {
      tab: 'overview',
    }
  },
}
</script>

<style scoped></style>
