<template>
  <v-card height="100%" style="background-color: transparent;  position: relative;" width="100%">
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout class="fill-height pa-2" column >
      <v-layout column style="position: relative;">
        <div class="py-2">
          <v-layout>
            <select2
              v-model="filter.aoi_id"
              :disabled="drawing || advSearch"
              :items="combinedList"
              :label="drawing ? 'AOI draw' : advSearch ? 'AOI Admin Boundary' : 'Select AOI'"
              clearable
              dense
              hide-details
              item-text="name"
              item-value="uuid"
              outlined
              placeholder="Select AOI"
              @change="zoomToAOI(true)"
            />
            <!-- <v-menu offset-y>
              <template #activator="{ on: onMenu }">
                <v-tooltip bottom>
                  <template #activator="{ on: onTooltip }">
                    <v-btn
                      :color="draw || drawing ? '#ffe600' : ''"
                      class="mt-2"
                      icon
                      small
                      v-on="{ ...onMenu, ...onTooltip }"
                    >
                      <v-icon>mdi-shape-polygon-plus</v-icon>
                    </v-btn>
                  </template>

                  <span>Draw AOI</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item dense @click="drawAOI">
                  <v-list-item-title>
                    <v-icon :color="drawing ? '#ffe600' : ''" class="mr-2">mdi-shape-polygon-plus </v-icon>
                    Draw free AOI
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="filter.aoi_id" dense @click="drawInAOI">
                  <v-list-item-title>
                    <v-icon :color="draw ? '#ffe600' : ''" class="mr-2">mdi-shape-polygon-plus </v-icon>
                    Draw in the selected AOI area
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
          </v-layout>
          <v-row align-center class="mt-1 mb-1">
            <v-col cols="6" class="d-flex align-center ">
              <div class="ml-1">Or create new AOI:</div>
            </v-col>
            <v-col cols="6" class="d-flex justify-end ">
              <v-btn small width="180" color="primary" @click="$refs.AOISelect.openDialog()">New AOI</v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- <div class="py-2 px-2" style="flex: none; width: 100%">
          <v-layout align-center class="mb-2">
            Administrator boundary
            <v-divider class="mx-2" />
            <v-btn icon small @click="advSearch = !advSearch">
              <v-icon>{{ advSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-layout>
          <div v-show="advSearch" class="pa-2" style="width: 100%; border-radius: 6px; border: 2px solid #43466A">
            <AdministratorBoundary ref="adminBoundary" @geometry="addGeojsonToMap" />
          </div>
        </div> -->
      </v-layout>
      <div class="py-2">
        <v-select
          v-model="filter.types"
          :items="sources"
          deletable-chips
          dense
          hide-details
          item-text="display_name"
          item-value="id"
          label="Select source"
          multiple
          outlined
          placeholder="Source"
          return-object
          small-chips
        >
          <template v-slot:selection="data">
            <v-chip
              :input-value="data.selected"
              class="mt-1"
              close
              color="primary"
              small
              v-bind="data.attrs"
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.display_name }}
            </v-chip>
          </template>
        </v-select>
      </div>
      <div style="min-height: 50px; max-height: 180px; overflow-y: auto; flex: none;">
        <div v-for="(item, index) in arrDateRange" class="py-2 mt-2">
          <v-layout align-center>
            <DateRangerPicker v-model="item.dateRange" label="Select time" />
            <v-btn
              v-if="arrDateRange.length > 1"
              color="error"
              height="48"
              class="ml-2"
              icon
              min-width="0"
              width="48"
              @click="arrDateRange.splice(index, 1)"
            >
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>
          </v-layout>
        </div>
      </div>
      <div class="py-1" style="text-align: center">
        <v-btn
          color="primary"
          min-width="0"
          small
          style="text-transform: none"
          @click="arrDateRange.push({ dateRange: undefined })"
        >
          <v-icon class="mr-1" size="21">mdi-plus</v-icon>
          Add another range
        </v-btn>
      </div>
      <div>
        <!-- <v-text-field
          v-model.number="filter.cloud"
          dense
          hide-details
          label="Cloud percent"
          max="100"
          min="0"
          outlined
          placeholder="Cloud percent"
          suffix="%"
          type="number"
          @change="cloudChange"
        /> -->
        <v-layout
          row
          align-center
          justify-center
          class=" pl-2  mt-3 mb-3"
          style="height: 40px;width: 316px; border: 2px solid #474c6e; border-radius: 10px;margin-left: 2px"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-1" v-bind="attrs" v-on="on">mdi-cloud-outline</v-icon>
            </template>
            <span>Cloud percent</span>
          </v-tooltip>
          <v-range-slider :min="0" :max="100" color="#b0abc6" hide-details thumb-label v-model="filter.cloud" />
          <span class="ml-1" style="width:30%;">{{ filter.cloud[0] }}% - {{ filter.cloud[1] }}%</span>
        </v-layout>
      </div>
      <div class="py-2 align-center text-center">
        <v-btn :loading="loading" color="primary" rounded width="100%" @click="searchImage">
          Search
        </v-btn>
      </div>
      <div class="py-2 align-center text-start">
        <v-spacer />
        <a
          style="text-transform: none; color: white;  text-decoration: underline"
          @click="() => $refs.listOrder.openDialog()"
        >
          <v-icon class="mr-2">mdi-cart-outline</v-icon>
          Ordered images
        </a>
      </div>
      <v-divider class="my-2" />
      <v-layout class="overflow-y-auto" column fill-height style="min-height: 180px">
        <v-expansion-panels class="custom-expansion">
          <v-expansion-panel v-for="(item, i) in group" :key="i">
            <v-expansion-panel-header class="py-1">
              <v-layout align-center>
                <div class="mr-3" style="width: 75px; height: 65px">
                  <v-img :lazy-src="item.thumbnail" :src="item.thumbnail">
                    <template v-slot:placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <v-layout column justify-center>
                  <div style="font-size: 13px">Date: {{ item.date }}</div>
                  <div class="caption pt-2" style="font-size: 12px !important;">
                    Total item: {{ item.images.length }}
                  </div>
                </v-layout>
                <v-spacer />
                <v-btn
                  :color="
                    !item.images.filter(image => image.display).length
                      ? ''
                      : item.images.filter(image => image.display).length === item.images.length
                      ? 'info'
                      : 'warning'
                  "
                  icon
                  small
                  @click.native.stop="changeDisplayGroup(item)"
                >
                  <v-icon size="20">icon-eye</v-icon>
                </v-btn>
              </v-layout>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-layout column>
                <div
                  v-for="(image, index) in item.images"
                  :key="index"
                  class="my-1 custom-card-bg-1"
                  style="width: 100%; min-height: 70px; position: relative"
                >
                  <BorderArrow v-if="image.filter" />
                  <v-layout align-center class="pa-2" fill-height>
                    <div style="width: 75px; height: 70px">
                      <v-img :lazy-src="image.thumbnail" :src="image.thumbnail">
                        <template v-slot:placeholder>
                          <v-row align="center" class="fill-height ma-0" justify="center">
                            <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                    <v-layout class="pl-4" column fill-height justify-center>
                      <v-layout align-center style="font-size: 12.25px">
                        Acquired: {{ convertDate(image.properties.acquired) }}
                        <v-spacer />
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon x-small v-bind="attrs" v-on="on" @click="zoomTo(image)">
                              <v-icon size="16">icon-gps</v-icon>
                            </v-btn>
                          </template>
                          <span>Zoom to</span>
                        </v-tooltip>
                      </v-layout>
                      <div class="caption">
                        <v-layout align-center style="font-size: 12px">
                          Cloud percent: {{ image.properties.cloud_percent }}%
                          <v-spacer />
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :color="image.display ? 'info' : ''"
                                  icon
                                  x-small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="changeDisplay(image)"
                                >
                                  <v-icon size="16">icon-eye</v-icon>
                                </v-btn>
                              </template>
                              <span>Add to map</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="currentUser.permissions.some(val => val === 'map.discovery.ssr_tile')"
                                  icon
                                  x-small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="addSSRTile(image)"
                                >
                                  <v-icon size="16">mdi-map</v-icon>
                                </v-btn>
                              </template>
                              <span>Add SSR tile to map</span>
                            </v-tooltip>
                          </div>
                        </v-layout>
                      </div>
                      <div class="caption">
                        <v-layout style="font-size: 12px">
                          Area coverage: {{ image.area_coverage.toFixed(2) }}%
                          <v-spacer />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="
                                  image._permissions.toString().includes(':download') &&
                                    currentUser.permissions.includes('planet.order.submit')
                                "
                                class="mr-1"
                                size="16"
                                v-bind="attrs"
                                v-on="on"
                                @click="() => $refs.makeOrder.openDialog(image, filter.geojson)"
                              >
                                mdi-cart-outline
                              </v-icon>
                            </template>
                            <span>Make order</span>
                          </v-tooltip>
                        </v-layout>
                      </div>
                    </v-layout>
                  </v-layout>
                </div>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>
    </v-layout>
    <MakeOrder ref="makeOrder" />
    <ListOrder ref="listOrder" />
    <AOISelect @startDraw="drawAOI()" ref="AOISelect" @addAdminBoundary="() => $emit('addAdminBoundary')" />
  </v-card>
</template>
<script>
import { mapState } from '@/store/ults'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import { getDetailAOI } from '@/api/aoi-api'
import PlanetApi from '@/utils/planet/planetApi'
import BorderArrow from '@/components/BorderArrow.vue'
import ConvertDate from '@/utils/convertDate'
import bbox from '@turf/bbox'
import CustomIcon from '@/components/CustomIcon.vue'
import sleep from '@/utils/sleep'
import polygonClipping from 'polygon-clipping'
import turf from 'turf'
import AdministratorBoundary from '@/components/AdministratorSearch.vue'
import MakeOrder from '@/components/imagery-coverage/MakeOrder.vue'
import SOURCES from '@/constants/planet-sources'
import ListOrder from '@/components/imagery-coverage/ListOrder.vue'
import booleanWithin from '@turf/boolean-within'
import Select2 from '@/components/Select2/Select2.vue'
import AOISelect from '@/views/forest-fire/AOISelect.vue'

export default {
  name: 'ImageCoverage',
  components: {
    Select2,
    ListOrder,
    MakeOrder,
    AdministratorBoundary,
    CustomIcon,
    BorderArrow,
    DateRangerPicker,
    AOISelect,
  },
  data() {
    return {
      currentAOIGeojson: undefined,
      draw: false,
      selectedAOI: undefined,
      drawing: false,
      sources: SOURCES,
      loading: false,
      group: [],
      addedImage: [],
      oldAOI: undefined,
      arrDateRange: [
        {
          dateRange: undefined,
        },
      ],
      filter: {
        cloud: [0, 100],
        geojson: undefined,
        aoi_id: undefined,
        types: [
          {
            _links: {
              _self: 'https://api.planet.com/data/v1/item-types/PSScene',
            },
            display_description: '8-band PlanetScope imagery that is framed as captured.',
            display_name: 'PlanetScope Scene',
            id: 'PSScene',
            supported_asset_types: [
              'basic_analytic_4b',
              'basic_analytic_8b',
              'basic_analytic_8b_xml',
              'basic_analytic_4b_rpc',
              'basic_analytic_4b_xml',
              'basic_udm2',
              'ortho_analytic_3b',
              'ortho_analytic_3b_xml',
              'ortho_analytic_4b',
              'ortho_analytic_4b_sr',
              'ortho_analytic_4b_xml',
              'ortho_analytic_8b',
              'ortho_analytic_8b_sr',
              'ortho_analytic_8b_xml',
              'ortho_udm2',
              'ortho_visual',
            ],
          },
        ],
      },
    }
  },
  props: {
    advSearch: {
      type: Boolean,
      default: false,
    },
    currentLayers: {
      type: Array,
      default: () => [],
    },
    isDraw: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
    },
  },
  watch: {
    // 'filter.aoi_id'() {
    //   this.$emit('removeSearchLayer')
    // },

    currentLayers() {
      let removedLayer = []
      this.addedImage.forEach(image => {
        if (!this.currentLayers.some(layer => layer.dataId === image.id)) {
          removedLayer.push(image)
        }
      })
      removedLayer.forEach(layer => {
        layer.display = false
        let index = this.addedImage.findIndex(image => image.id === layer.id)
        if (index >= 0) this.addedImage.splice(index, 1)
      })
    },
    isDraw(val) {
      this.drawing = val
    },
    tab(newVal, oldVal) {
      if (oldVal !== 0 && newVal === 2) {
        this.filter.aoi_id = undefined
        ;(this.filter.types = [
          {
            _links: {
              _self: 'https://api.planet.com/data/v1/item-types/PSScene',
            },
            display_description: '8-band PlanetScope imagery that is framed as captured.',
            display_name: 'PlanetScope Scene',
            id: 'PSScene',
            supported_asset_types: [
              'basic_analytic_4b',
              'basic_analytic_8b',
              'basic_analytic_8b_xml',
              'basic_analytic_4b_rpc',
              'basic_analytic_4b_xml',
              'basic_udm2',
              'ortho_analytic_3b',
              'ortho_analytic_3b_xml',
              'ortho_analytic_4b',
              'ortho_analytic_4b_sr',
              'ortho_analytic_4b_xml',
              'ortho_analytic_8b',
              'ortho_analytic_8b_sr',
              'ortho_analytic_8b_xml',
              'ortho_udm2',
              'ortho_visual',
            ],
          },
        ]),
          (this.arrDateRange = [
            {
              dateRange: undefined,
            },
          ])
        this.filter.cloud = [0, 100]
        if (this.$refs.adminBoundary) this.$refs.adminBoundary.reset()      }
    },
  },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    ...mapState('auth', ['currentUser']),
    combinedList() {
      if (this.currentLayers.length === 0) return this.listAOI
      const filteredCurrentLayers = {
        items: this.filterMapLayers(this.currentLayers),
        name: 'Map layers',
      }
      return [filteredCurrentLayers, ...this.listAOI]
    },
    withMapLayersAOIs() {
      if (this.currentLayers.length === 0) return this.AOIs
      const filteredCurrentLayersAoi = this.filterMapLayers(this.currentLayers)
      return [...filteredCurrentLayersAoi, ...this.AOIs]
    },
  },
  methods: {
    filterMapLayers(layers) {
      return layers
        .filter(
          layer =>
            layer.type !== 'raster' &&
            (layer.geometry.features[0].geometry.type == 'Polygon' ||
              layer.geometry.features[0].geometry.type == 'MultiPolygon'),
        )
        .map(layer => {
          if (layer.geometry.features.length === 1) {
            let newLayer = { ...layer, uuid: layer.id }
            delete newLayer.id
            return newLayer
          }
          return layer
        })
    },
    addGeojsonToMap(data) {
      if (!data.data.length) {
        this.$emit('resetDraw')
        return
      }
      let features = []
      data.data.forEach(geometry => {
        features.push({
          type: 'Feature',
          properties: {},
          geometry: geometry,
        })
      })

      let featureCollection = {
        type: 'FeatureCollection',
        features: features,
      }
      this.$emit('drawAOI', featureCollection)
    },
    drawInAOI() {
      this.$emit('resetDraw')
      this.draw = !this.draw
      this.drawing = false
      let aoi = this.withMapLayersAOIs.find(val => val.uuid === this.filter.aoi_id)
      if (!aoi) return
      if (aoi.planet_key) PlanetApi.setApikey(aoi.planet_key)
      this.$emit('zoomTo', aoi.bbox)
      if (this.draw) this.addAOIGeojsonToMap(aoi)
      else {
        this.$emit('removeAOI', this.selectedAOI)
        this.addToMap(aoi)
      }
      this.$emit('changeDrawTool', this.draw)
    },
    drawAOI() {
      if (this.selectedAOI) this.$emit('removeAOI', this.selectedAOI)
      this.draw = false
      this.currentAOIGeojson = undefined
      this.$emit('resetDraw')
      this.drawing = !this.drawing
      if (this.oldAOI) this.$emit('removeAOI', this.oldAOI)
      this.filter.aoi_id = undefined
      this.oldAOI = undefined
      this.$emit('changeDrawTool', this.drawing)
    },
    remove(item) {
      const index = this.filter.types.findIndex(source => source.id === item.id)
      if (index >= 0) this.filter.types.splice(index, 1)
    },
    cloudChange(val) {
      if (this.filter.cloud === null || this.filter.cloud < 0) this.filter.cloud = 0
      else if (this.filter.cloud > 100) this.filter.cloud = 100
    },
    async searchImage() {
      try {
        await this.$emit('getGeometry')
        if (!this.filter.geojson || this.filter.geojson.coordinates.length == 0 ) {
          this.$store.commit('message/SHOW_ERROR', 'AOI is required')
          return
        }
        if (this.draw && this.currentAOIGeojson) {
          let a = {
            type: 'Feature',
            properties: {},
            geometry: this.filter.geojson,
          }

          if (!booleanWithin(a, this.currentAOIGeojson.geojson.features[0])) {
            this.$store.commit('message/SHOW_ERROR', 'Geometry needs to be within the selected AOI')
            return
          }
        }
        if (!this.filter.types.length) {
          this.$store.commit('message/SHOW_ERROR', 'Source is required')
          return
        }
        this.group = []
        this.loading = true
        this.$emit('removeSearchLayer')
        let images = []
        for (let i = 0; i < this.arrDateRange.length; i++) {
          images = images.concat(await this.requestGetResult(this.arrDateRange[i].dateRange))
        }
        if (images.length == 0) {
          this.$store.commit('message/SHOW_ERROR', 'No image found')
          return
        }
        images.forEach(image => {
          let polygonIntersection = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  coordinates: polygonClipping.intersection(
                    image.geometry.coordinates,
                    this.filter.geojson.coordinates,
                  )[0],
                  type: 'Polygon',
                },
              },
            ],
          }
          image.area_coverage = (turf.area(polygonIntersection) / turf.area(this.filter.geojson)) * 100
          let index = this.group.findIndex(val => val.date === ConvertDate.dateFormatted(image.properties.acquired))
          if (index >= 0) {
            this.group[index].images.push(image)
          } else {
            this.group.push({
              date: ConvertDate.dateFormatted(image.properties.acquired),
              images: [image],
              thumbnail: image.thumbnail,
            })
          }
        })
        this.group = this.group.sort((a, b) => {
          return new Date(b.date) - new Date(a.date)
        })
        this.$emit('disableDraw')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async requestGetResult(dateRange) {
      try {
        this.loading = true
        let filter = JSON.parse(JSON.stringify(this.filter))
        filter.date_range = dateRange
        return await PlanetApi.quickSearch(filter)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    zoomToAOI(removeDraw = false) {
      if (this.selectedAOI) {
        // if (!this.currentLayers.map(layer => layer.uuid).includes(this.selectedAOI)) {
        //   this.$emit('removeAOI', this.selectedAOI)
        // }

        this.selectedAOI = this.filter.aoi_id
      }
      if (removeDraw) this.$emit('resetDraw')
      if (!this.filter.aoi_id) return
      this.selectedAOI = this.filter.aoi_id
      this.drawing = false
      // this.$emit('changeDrawTool', false)

      let aoi = this.withMapLayersAOIs.find(val => val.uuid === this.filter.aoi_id)
      if (!aoi) return
      if (aoi.planet_key) PlanetApi.setApikey(aoi.planet_key)
      this.$emit('zoomTo', aoi.bbox)
      if (this.draw) this.addAOIGeojsonToMap(aoi)
      else this.addToMap(aoi)
    },
    async addAOIGeojsonToMap(AOI) {
      try {
        this.loading = true
        const res = await this.getInfoAOI(AOI)
        this.$emit('addGeoJsonToMap', res)
        this.currentAOIGeojson = res
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async addToMap(AOI) {
      try {
        this.loading = true
        this.currentAOIGeojson = undefined
        let res
        if (this.currentLayers.map(layer => layer.id).includes(AOI.uuid)) {
          res = { geojson: AOI.geometry }
        } else {
          res = await this.getInfoAOI(AOI)
        }

        this.$emit('drawAOI', res.geojson)
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI(AOI) {
      try {
        this.loading = true
        // if (this.oldAOI) this.$emit('removeAOI', this.oldAOI)
        // this.oldAOI = AOI.uuid
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getGeometry() {
      try {
        this.loading = true
        let res = await getDetailAOI({
          projectId: this.$route.params.id,
          id: this.filter.aoi_id,
          payload: { weather: false },
        })
        this.filter.geojson = res.data.geojson
      } catch (e) {
        this.loading = false
      } finally {
      }
    },
    changeDisplayGroup(group) {
      group.images.forEach(image => this.changeDisplay(image))
    },
    async changeDisplay(image) {
      let index = this.addedImage.findIndex(img => img.id === image.id)
      if (index >= 0) {
        this.addedImage.splice(index, 1)
        this.$emit('removeLayer', image.id)
        image.display = false
      } else {
        this.addedImage.push(image)
        this.$emit('addThumbnailToMap', image)
        this.loading = true
        await sleep(400)
        this.loading = false
        this.zoomTo(image)
        image.display = true
      }
    },
    async addSSRTile(image) {
      this.$emit('addSSRTileToMap', image)
      this.loading = true
      await sleep(400)
      this.loading = false
      this.zoomTo(image)
    },
    zoomTo(image) {
      this.$emit('zoomTo', bbox(image.geometry))
    },
    convertDate(date) {
      return ConvertDate.dateTimeFormatted(date)
    },
  },
}
</script>

<style scoped></style>
