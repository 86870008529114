<template>
  <v-layout class="fill-height overflow-y-hidden">
    <AOIManagement
      :AOIMenu.sync="AOIMenu"
      :loading="loading"
      :isCreating.sync="isCreating"
      @updateAOIMenu="updateAOIMenu"
      @startDraw="() => (isCreating = true)"
      @addToMap="addToMap"
      @editAOI="editAOI"
      @removeAOI="removeAOI"
      @makeOrder="AOI => $refs.makeOrderDialog.openDialog(AOI)"
      @zoomTo="bbox => $refs.map.submitZoom(bbox)"
    >
    </AOIManagement>

    <v-layout class="fill-height px-4">
      <v-card height="100%" style="position: relative;" width="100%">
        <v-overlay :value="mapLoading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div v-if="!isCreating" style="z-index: 2; position: absolute; top: 10px; left: 10px">
          <MakeOrder ref="makeOrderDialog" />
        </div>
        <v-btn
          v-if="!AOIMenu"
          color="bgContainer"
          fab
          style="position: absolute; bottom: 10px; left: 10px; z-index: 2"
          @click="AOIMenu = true"
          small
        >
          <v-icon>icon-layers</v-icon>
        </v-btn>
        <map-view ref="map" :currentLayers.sync="layers" :isDraw.sync="isCreating" isLimitedDraw />
      </v-card>
    </v-layout>

    <CreateAOI ref="createAOI"  />
    <UploadAOI ref="uploadAOI"  />
  </v-layout>
</template>

<script>
import MapView from '@/components/Map.vue'
import CreateAOI from '@/views/aoi/CreateAOI.vue'
import UploadAOI from '@/views/aoi/UploadAOI.vue'
import { getDetailAOI, getGroupAOI } from '@/api/aoi-api'
import { mapState } from '@/store/ults'
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
import sleep from '@/utils/sleep'
import bbox from '@turf/bbox'
import utils from '@/utils/genUUID'
import turf from 'turf'
import AOIManagement from './AOIManagement.vue'

export default {
  name: 'AOI',
  components: {
    MakeOrder,
    UploadAOI,
    CreateAOI,
    MapView,
    AOIManagement,
  },
  data() {
    return {
      mapLoading: false,
      loading: false,
      editing: false,
      currentAOI: null,
      isCreating: false,
      AOIMenu: true,
      layers: [],
    }
  },
  watch: {
    AOIMenu() {
      this.$refs.map.reSize()
    },
    isCreating(val) {
      if (!val) this.currentAOI = null
      if (this.$refs.listAOI?.currentAOI && Object.keys(this.$refs.listAOI.currentAOI).length) {
        this.$refs.listAOI.changeDisplayAOI(this.$refs.listAOI.currentAOI)
      }
      this.$refs.map?.deleteAllDraw()
    },
    projectArea(val) {
      if (val) this.addBoundary()
    },
  },
  mounted() {
    this.getGroupAOI()
    if (this.projectArea) this.addBoundary()
  },
  computed: {
    ...mapState('AOI', ['listAOI', 'groups']),
    ...mapState('project', ['projectArea']),
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    updateAOIMenu(val) {
      this.AOIMenu = val
    },
    async openSaveDialog() {
      try {
        let geometry = this.$refs.map.getAllDrawFeature()
        if (this.currentAOI) {
          let tmpGeometry = {
            coordinates: [],
            type: 'MultiPolygon',
          }
          let multiPolygon = geometry.find(val => val.geometry.type === 'MultiPolygon')
          tmpGeometry.coordinates = multiPolygon ? multiPolygon.geometry.coordinates : []
          geometry.forEach(feature => {
            if (feature.geometry.type !== 'MultiPolygon') {
              tmpGeometry.coordinates.push(feature.geometry.coordinates)
            }
          })
          let tmpAOI = { ...this.currentAOI }
          tmpAOI.geometry = tmpGeometry
          await this.$refs.listAOI.updateInfo(tmpAOI)
          this.currentAOI = null
          this.isCreating = false
        } else this.$refs.createAOI.openDialog(geometry)
      } catch (e) {
      } finally {
      }
    },
    async getGroupAOI() {
      try {
        this.loading = true
        const res = await getGroupAOI({ projectId: this.$route.params.id })
        this.groups = res.data.filter(item => item.group !== '').map(item => item.group);
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI(AOI) {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addToMap(AOI) {
      try {
        this.loading = true
        const res = await this.getInfoAOI(AOI)
        this.$refs.map.addGeoJsonToMap(res.name, res.geojson, 'red', res.uuid, 'line', true)
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    removeAOI() {
      this.$refs.map.removeAllLayer()
    },
    async editAOI(AOI) {
      try {
        this.loading = true
        const res = await this.getInfoAOI(AOI)
        this.isCreating = true
        this.$nextTick(() => {
          let features = []
          res.geojson.features[0].geometry.coordinates.forEach(val => {
            features.push({
              type: 'Feature',
              properties: {},
              geometry: {
                coordinates: val.length > 1 ? [val] : val,
                type: 'Polygon',
              },
            })
          })
          res.geojson.features = features
          this.$refs.map.drawAOI(res.geojson)
          this.currentAOI = AOI
          this.$refs.map.submitZoom(AOI.bbox)
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async addBoundary() {
      if (!this.$refs.map) {
        await sleep(100)
        await this.addBoundary()
      } else {
        try {
          this.mapLoading = true
          await sleep(500)
          let difference = turf.difference(this.$refs.map.mapBbox, this.projectArea)
          let featureCollection = {
            type: 'FeatureCollection',
            features: [{ type: 'Feature', properties: {}, geometry: this.projectArea }],
          }
          this.$refs.map.addGeoJsonToMap('Boundary', difference, '#0288D1', utils.getUUID(), 'fill', false, 'fill', {
            'fill-color': '#001c96',
            'fill-opacity': 0.5,
          })
          this.mapLoading = false
          this.$refs.map.submitZoom(bbox(featureCollection))
        } catch (e) {
        } finally {
          this.mapLoading = false
        }
      }
    },
  },
}
</script>

<style scoped></style>
