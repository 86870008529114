<template>
  <v-layout class="fill-height">
    <v-card v-show="leftMenu" :loading="loading" class="AOI-menu custom-card-bg-1 mr-4" height="100%" width="340">
      <v-btn class="map-overview-menu" color="bgContainer" fab @click="leftMenu = false">
        <v-icon size="26">icon-chevrons_left</v-icon>
      </v-btn>
      <v-tabs
        v-model="tab"
        fixed-tabs
        height="48"
        style="max-height: 48px; border-top-left-radius: 8px; border-top-right-radius: 8px"
      >
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab class="ml-1 custom-tab-name">
          Layers
        </v-tab>
        <v-tab class="ml-0 custom-tab-name">
          Data
        </v-tab>
        <v-tab class="ml-0 custom-tab-name">
          Discovery
        </v-tab>
        <v-spacer />
        <v-divider vertical />
        <v-btn
          class="top-menu-map-overview"
          color="primary"
          min-height="0"
          min-width="0"
          style="height: 100% !important;"
          text
          title="Hide"
          width="20"
          @click="leftMenu = false"
        >
          <v-icon size="26">mdi-menu-down</v-icon>
        </v-btn>
      </v-tabs>
      <v-divider />
      <v-tabs-items
        v-model="tab"
        class="custom-menu-tab"
        style="height: calc(100% - 45px);background-color:transparent;overflow:hidden;"
      >
        <v-tab-item style="height: 100%">
          <v-card class="custom-card-bg-0" flat height="100%">
            <LayerControl
              ref="layerControl"
              :current-layers.sync="layers"
              :isDrawing.sync="isDraw"
              comparable
              map-control
              removable
              transparent
              @cancel="cancelCreate"
              @changeCompare="show => $refs.map.changeCompare(show)"
              @changeToSyncMap="status => ($refs.map.mapSync = status)"
              @changeVisible="layer => $refs.map.changeVisibility(layer.id)"
              @changeVisibleLeftLayer="(layer, isSync) => $refs.map.changeVisibleLeftLayer(layer.id)"
              @createLayer="createLayer"
              @reloadMap="() => $refs.map.reloadMap()"
              @removeAllLayer="() => $refs.map.removeAllLayer()"
              @removeLayer="layerId => $refs.map.removeLayer(layerId)"
              @saveLayer="saveLayer"
              @setLayer="setLayer"
              @addToMap="data => addToMap(data)"
              @updateStyleProperties="layerId => $refs.map.updateStyleProperties(layerId)"
              @zoomTo="bbox => $refs.map.submitZoom(bbox)"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item style="height: 100%; ">
          <v-card class="custom-card-bg-0 py-2" flat height="100%">
            <ListData :data.sync="allData" @addToMap="addToMap" @onFilter="getDataExplorer" />
          </v-card>
        </v-tab-item>
        <v-tab-item style="height: 100%; ">
          <v-card class="custom-card-bg-0" flat height="100%">
            <ImageCoverage
              ref="daily"
              :current-layers.sync="layers"
              @addGeoJsonToMap="addGeoJsonToMap"
              @addSSRTileToMap="addSSRTileToMap"
              @addThumbnailToMap="addThumbnailToMap"
              @changeDrawTool="changeDrawTool"
              @drawAOI="aoi => $refs.map.drawAOI(aoi)"
              @getGeometry="getGeometry"
              @removeAOI="id => $refs.map.removeLayer(id)"
              @removeAllLayer="() => $refs.map.removeAllLayer()"
              @removeLayer="removeData"
              @removeSearchLayer="removeSearchLayer"
              @resetDraw="() => $refs.map.resetDraw()"
              @zoomTo="bbox => $refs.map.submitZoom(bbox)"
              :isDraw="isDraw"
              @addAdminBoundary="
                () => {
                  isAdminBoundary = true
                  resetDraw()
                }
              "
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-layout class="fill-height">
      <v-card height="100%" style="position: relative" width="100%">
        <v-btn
          v-if="!leftMenu"
          color="bgContainer"
          fab
          style="position: absolute; bottom: 12px; left: 12px; z-index: 2"
          @click="leftMenu = true"
        >
          <v-icon>icon-layers</v-icon>
        </v-btn>
        <Map
          ref="map"
          :current-layers.sync="layers"
          :drawType.sync="drawType"
          :is-draw.sync="isDraw"
          :isCreateText="displayType === 'text'"
        />
        <v-card
          v-if="isAdminBoundary"
          style="position:absolute;top:10px;left:10px;width:400px"
          class="pa-3 custom-card-bg-2"
        >
          <v-row class="ma-1">
            <h2 class="mb-2">Admin Boundary</h2>
            <v-spacer></v-spacer>
            <v-icon @click="isAdminBoundary = false">mdi-close</v-icon>
          </v-row>
          <AdministratorBoundary ref="adminBoundary" @geometry="addAdminBoundary" />
        </v-card>
      </v-card>
    </v-layout>
  </v-layout>
</template>

<script>
import Map from '@/components/Map'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import Daily from '@/views/map-overview/Daily.vue'
import bbox from '@turf/bbox'
import ListData from '@/components/data-control/ListData.vue'
import { getDataExplorer } from '@/api/explorer-api'
import VectorStyle from '@/utils/VectorStyle'
import ImageCoverage from '@/components/imagery-coverage/ImageryCoverage.vue'
import sleep from '@/utils/sleep'
import utils from '@/utils/genUUID'
import { mapState } from '@/store/ults'
import config from '@/config'
import AdministratorBoundary from '@/components/AdministratorSearch.vue'
import randomColor from '@/utils/randomColor'
// import DataExplorerModel from '@/models/data/data-explorer'

export default {
  name: 'MapView',
  components: { ImageCoverage, ListData, Daily, LayerControl, Map,AdministratorBoundary },
  data() {
    return {
      isDraw: false,
      layerName: '',
      drawType: '',
      displayType: '',
      loading: false,
      tab: 0,
      layers: [],
      oldLayers: [],
      searchLayers: [],
      leftMenu: true,
      allData: [],
      isAdminBoundary: false,
    }
  },
  computed: {
    ...mapState('layers', ['savedLayers']),
  },
  created() {},
  mounted() {
    this.getSavedMap()
  },
  watch: {
    tab(val) {
      this.$refs.map.deleteAllDraw()
      if (this.$refs.daily) this.$refs.daily.drawing = false
      this.isDraw = false
      switch (val) {
        case 1:
          this.getDataExplorer()
          break
        case 2:
          this.$refs.layerControl.changeMapDisplay('overlay')
          let layerWithoutSearch = this.layers.filter(
            layer => !this.searchLayers.some(image => image.id === layer.dataId),
          )
          layerWithoutSearch.forEach(layer => {
            this.$refs.map.changeVisibility(layer.id, 'none')
          })
          if (this.$refs.daily) this.$refs.daily.zoomToAOI()
          this.getAOI()
          break
        default:
          this.$refs.map.reloadMap()
      }
    },
    layers() {
      let removedLayer = []
      this.searchLayers.forEach(image => {
        if (!this.layers.some(layer => layer.dataId === image.id)) {
          removedLayer.push(image)
        }
      })
      removedLayer.forEach(layer => {
        let index = this.searchLayers.findIndex(image => image.id === layer.id)
        if (index >= 0) this.searchLayers.splice(index, 1)
      })
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
  },
  methods: {
    addAdminBoundary(data) {
      this.isDraw = false
      if (this.$refs.daily) this.$refs.daily.addGeojsonToMap(data)
    },
    resetDraw() {
      this.$refs.map.resetDraw()
      if (this.$refs.daily) {
        this.$refs.daily.filter.aoi_id = undefined
        this.$refs.daily.drawing = false
      }
      this.isDraw = false
    },
    async getSavedMap() {
      if (this.savedLayers.length) {
        if (!this.$refs.map) {
          await sleep(100)
          await this.getSavedMap()
          return
        }
        await sleep(100)
        this.layers = this.savedLayers
        await this.reloadMap()
      }
    },
    async reloadMap() {
      if (!this.$refs.map) {
        await sleep(100)
        await this.reloadMap()
        return
      }
      await sleep(500)
      this.$refs.map.reloadMap()
    },
    cancelCreate() {
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    saveLayer() {
      let features = this.$refs.map.getAllDrawFeature()

      let vectorType
      switch (this.displayType) {
        case 'polygon':
        case 'line':
          vectorType = 'line'
          break
        case 'point':
          vectorType = 'circle'
          break
      }
      if (this.displayType === 'marker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addMarkerSymbolToMap(this.layerName, featureCollection, 'yellow')
      } else if (this.displayType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addTextToMap(this.layerName, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addGeoJsonToMap(this.layerName, featureCollection, 'yellow', utils.getUUID(), vectorType, true)
      }
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    setLayer(name, geojson) {
      this.$refs.map.addGeoJsonToMap(name, geojson, '#e2ed09', utils.getUUID(), 'line', true)
    },
    createLayer(name, type) {
      this.displayType = type
      this.layerName = name
      this.$refs.map.createLayer(name, type)
      switch (type) {
        case 'polygon':
        case 'line':
          this.drawType = 'polygon'
          break
        case 'marker':
        case 'text':
          this.drawType = 'point'
          break
      }
      if (type === 'arrow') this.$refs.map.drawArrow()
      else this.isDraw = true
    },

    getGeometry() {
      this.$refs.daily.filter.geojson = this.$refs.map.getAllDrawFeature()[0].geometry
    },
    changeDrawTool(status) {
      this.isDraw = status
    },
    async getDataExplorer(payload = {}) {
      try {
        this.loading = true
        payload.includes = ['type']
        const res = await getDataExplorer({
          payload: payload,
          projectId: this.$route.params.id,
        })
        // this.allData = res.data.map(item => new DataExplorerModel(item))
        this.allData = res.data.map(item => {
          if (item.tile_info.type == 'aoi')
            item.tile_info.thumbnail_url = `${config.backend_pub}${item.tile_info.thumbnail_url}`
          return item
        })
      } catch (e) {
        console.warn('Error when get data explorer: ', e)
      } finally {
        this.loading = false
      }
    },
    async getAOI() {
      await this.$store.dispatch('AOI/getListAOI', { projectId: this.$route.params.id })
    },
    addGeoJsonToMap(data) {
      this.$refs.map.addGeoJsonToMap(data.name, data.geojson, 'red', data.uuid, 'line', true)
    },
    removeData(id) {
      let layer = this.layers.find(val => val.dataId === id)
      if (layer) this.$refs.map.removeLayer(layer.id)
    },
    async addSSRTileToMap(image, beforeId) {
      let urls = []

      image.properties.tile_urls.forEach(val => {
        urls.push(
          config.ssr_tile +
            '?url=' +
            val
              .replace('{z}', ':z')
              .replace('{x}', ':x')
              .replace('{y}', ':y') +
            '&downscaled_zoom=16&x={x}&y={y}&z={z}',
        )
      })
      let tmpImg = {
        display: true,
        id: image.id,
        layerType: 'Raster_Image',
        name: image.properties.acquired + '_ssr',
        tiles: urls,
        bounds: bbox(image.geometry),
      }
      this.searchLayers.push(tmpImg)
      this.$refs.map.addImageToMap(tmpImg, beforeId)
      await sleep(200)
      this.$refs.map.moveDrawLayerToTop()
    },
    async addThumbnailToMap(image, beforeId) {
      let tmpImg = {
        display: true,
        id: image.id,
        layerType: 'Raster_Image',
        name: image.properties.acquired,
        tiles: image.properties.tile_urls,
        bounds: bbox(image.geometry),
      }
      this.searchLayers.push(tmpImg)
      this.$refs.map.addImageToMap(tmpImg, beforeId)
      await sleep(200)
      this.$refs.map.moveDrawLayerToTop()
    },
    addToMap(data) {
      if (data.tile_info.type === 'images') {
        let image = {
          id: data.name,
          display: true,
          data: data.tile_info,
          layerType: 'Raster_Image',
          name: data.name,
          tiles: [data.tile_info.tile_url],
          bounds: data.tile_info.bbox,
          meta: {
            MIN_ZOOM: data.MIN_ZOOM,
            MAX_ZOOM: data.MAX_ZOOM,
          },
        }
        this.$refs.map.addImageToMap(image)
        if (data.tile_info.bbox) this.$refs.map.submitZoom(data.tile_info.bbox)
      } else if (data.tile_info.type === 'aoi') {
        this.$refs.map.addGeoJsonToMap(
          data.name,
          data.geojson,
          randomColor.getColor(Math.floor(Math.random() * 10)),
          utils.getUUID(),
          'line',
          true,
        )
        this.$refs.map.submitZoom(bbox(data.geojson))
      } else if (data.tile_info.type === 'wms') {
        this.$refs.map.addWMSToMap(data)
      } else {
        this.$refs.map.addVectorTiles({
          display: true,
          data: data.tile_info,
          tiles: [data.tile_info.tile_url],
          bounds: data.tile_info.bbox,
          paint: VectorStyle.getStyle(data.tile_info.styles),
          layerName: 'default',
          name: data.name,
          id: data.name,
          type: data.tile_info.styles.type,
        })
        this.$refs.map.submitZoom(data.tile_info.bbox)
      }
      this.$store.commit('message/SHOW_SUCCESS', 'Success')
    },
    removeSearchLayer() {
      let layers = this.layers.filter(layer => this.searchLayers.some(image => image.id === layer.dataId))
      layers.forEach(layer => {
        this.$refs.map.removeLayer(layer.id)
      })
    },
  },
}
</script>

<style scoped></style>
