<template>
  <v-dialog v-model="dialog" persistent width="1000" style="z-index:9999">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Create your AOI'" @close="dialog = false" />
      <v-card-text>
        <div style="display: flex; flex-direction: row;">
          <v-layout
            column
            justify-center
            class="pa-5 ma-2"
            style="flex: 1; border: 1px solid #5d5d5d;border-radius: 10px"
          >
            <v-layout align-center justify-center style="height: 200px">
              <div class="" style="width: 100%;height: 100%;">
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  fit=""
                  preserveAspectRatio="xMidYMid meet"
                  focusable="false"
                >
                  <g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M24.339 4.06715L28.1262 6.12114L26.049 9.95132L22.2617 7.89733L24.339 4.06715ZM22.5809 3.11367C23.1075 2.1427 24.3215 1.78247 25.2925 2.30906L29.0797 4.36305C30.0507 4.88964 30.4109 6.10366 29.8843 7.07462L27.807 10.9048C27.2805 11.8758 26.0664 12.236 25.0955 11.7094L23.4715 10.8287L9.85869 35.9287L11.4825 36.8093C12.4534 37.3359 12.8137 38.5499 12.2871 39.5209L11.3932 41.1691L34.521 53.7123L35.4149 52.0641C35.9415 51.0931 37.1555 50.7329 38.1264 51.2595L41.9137 53.3135C42.8846 53.8401 43.2449 55.0541 42.7183 56.025L40.641 59.8552C40.1144 60.8262 38.9004 61.1864 37.9294 60.6598L34.1422 58.6058C33.1712 58.0792 32.811 56.8652 33.3376 55.8943L33.5675 55.4704L10.4397 42.9272L10.2098 43.3511C9.68319 44.322 8.46918 44.6823 7.49822 44.1557L3.71097 42.1017C2.74001 41.5751 2.37978 40.3611 2.90637 39.3901L4.98364 35.5599C5.51024 34.589 6.72425 34.2287 7.69521 34.7553L8.10061 34.9752L21.7134 9.87517L21.3082 9.65542C20.3373 9.12882 19.977 7.91481 20.5036 6.94385L22.5809 3.11367ZM6.74173 36.5134L10.529 38.5674L8.4517 42.3976L4.66446 40.3436L6.74173 36.5134ZM40.9602 55.0716L37.1729 53.0176L35.0957 56.8477L38.8829 58.9017L40.9602 55.0716Z"
                      fill="#C1CFDB"
                    ></path>
                    <path
                      d="M58.2479 15.9154C56.1199 14.8001 53.4909 15.6185 52.3719 17.7445L51.3613 19.6813L40.575 40.2044L40.5522 40.2483C40.5466 40.2589 40.5493 40.2675 40.5407 40.2701C40.5319 40.3003 40.5146 40.3327 40.506 40.3628C40.5086 40.3711 40.5 40.3738 40.5026 40.3821C40.4937 40.4122 40.4908 40.4314 40.4817 40.4616C40.4762 40.4724 40.4787 40.4805 40.4731 40.4916C40.4726 40.5192 40.4694 40.5384 40.4686 40.566C40.4711 40.5741 40.4631 40.5766 40.4657 40.5852L40.2436 52.1131C40.2342 52.4422 40.4142 52.7475 40.7068 52.8985C40.9124 53.0048 41.1515 53.0256 41.372 52.9561C41.4617 52.9264 41.5468 52.8846 41.625 52.8317L50.9795 46.1002C50.9876 46.0976 50.9876 46.0976 50.9936 46.0869C51.0175 46.0709 51.0392 46.0519 51.0578 46.0302C51.0641 46.0273 51.0689 46.0227 51.0722 46.0168C51.0917 45.9925 51.1197 45.9653 51.1395 45.9408C51.1591 45.9168 51.1765 45.884 51.1963 45.8596C51.202 45.8484 51.21 45.8459 51.2075 45.8378C51.2135 45.8268 51.2276 45.8136 51.2307 45.7941L63.0274 23.3339C64.1427 21.2059 63.3243 18.5769 61.1984 17.4581L58.2479 15.9154ZM50.1056 44.2298L42.5209 40.2463L52.4917 21.2625L60.0764 25.246L50.1056 44.2298ZM42.1847 42.0374L48.8108 45.5174L42.0093 50.4057L42.1847 42.0374ZM61.4936 22.535L60.8894 23.6986L53.3042 19.7147L53.9171 18.5487C54.5875 17.2735 56.1647 16.7825 57.4407 17.4519L60.4047 19.0086C61.6748 19.6855 62.1609 21.2602 61.4936 22.535Z"
                      fill="#C1CFDB"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="64" height="64" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </v-layout>
            <div
              class="custom-zone"
              style=" height: 175px;cursor: pointer;"
              @click="
                () => {
                  $emit('startDraw')
                  closeDialog()
                }
              "
            >
              <h3>Click to draw a AOI</h3>
              <div class="ml-2 mr-2" style="text-align: center;font-size: 12px; color:#a8a8a8;">
                Draw your field on a map, shaping polygon with a drawing tool
              </div>
            </div>
          </v-layout>
          <v-layout
            column
            justify-center
            class="pa-5 ma-2"
            style="flex: 1; border: 1px solid #5d5d5d;border-radius: 10px"
          >
            <v-layout align-center justify-center style="height: 200px">
              <div style="width: 250px; height: 220px">
                <v-img :src="require('@/assets/svg/create.svg')" />
              </div>
            </v-layout>
            <div
              class="custom-zone"
              style=" height: 175px;cursor: pointer;"
              @click="
                () => {
                  $emit('addAdminBoundary')
                  closeDialog()
                }
              "
            >
              <h3>Admin Boundary</h3>
              <div class="ml-2 mr-2" style="text-align: center;font-size: 12px; color:#a8a8a8;">
                Define your analysis area by simply selecting administrative boundaries (state, county etc.).
              </div>
            </div>
          </v-layout>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'

export default {
  components: { DialogHeader },
  data() {
    return {
      dialog: false,
      loading: false,
      geojson: {},
    }
  },
  props: {},
  watch: {},
  methods: {
    async openDialog() {
      this.dialog = true
      this.files = []
      this.geojson = {}
    },
    closeDialog() {
      this.$emit('cancelGuide')
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.custom-zone {
  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed #eee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-zone:hover {
  border: 2px dashed #2e94c4;
  color: #2e94c4;
}
</style>
