<template>
  <v-dialog v-model="dialog" persistent width="60vw">
    <v-card :loading="loading" class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Ordered images'" @close="dialog = false" />
      <v-card-text>
        <template>
          <v-data-table :headers="headers" :items="orders" :items-per-page="5" class="elevation-1">
            <template #[`item.created_on`]="{item}">
              {{ ConvertDate.dateTimeFormatted(item.created_on) }}
            </template>
            <template #[`item.state`]="{item}">
              <v-chip :color="item.state === 'success' ? 'success' : 'primary'" class="text-capitalize" small>
                {{ item.state }}
              </v-chip>
            </template>
            <template #[`item.action`]="{item}">
              <v-tooltip v-if="item.state === 'success'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon small v-bind="attrs" v-on="on" @click="downloadImage(item)">
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Download image</span>
              </v-tooltip>
            </template>
            <template v-slot:top>
              <v-layout class="pb-2">
                <v-spacer />
                <v-btn color="success" small text @click="getListOrder">
                  <v-icon>mdi-refresh</v-icon>
                  Refresh
                </v-btn>
              </v-layout>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import planetApi from '@/utils/planet/planetApi'
import ConvertDate from '../../utils/convertDate'

export default {
  components: { DialogHeader },
  data() {
    return {
      dialog: false,
      loading: false,
      orders: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Created on',
          align: 'start',
          sortable: true,
          value: 'created_on',
        },
        {
          text: 'State',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'Message',
          align: 'start',
          sortable: false,
          value: 'last_message',
        },
        {
          text: 'Action',
          align: 'start',
          sortable: false,
          value: 'action',
        },
      ],
    }
  },
  computed: {
    ConvertDate() {
      return ConvertDate
    },
  },
  methods: {
    openDialog() {
      this.dialog = true
      this.getListOrder()
    },
    async getListOrder() {
      try {
        this.loading = true
        let res = await planetApi.getListOrder()
        this.orders = res.data.orders
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async downloadImage(order) {
      try {
        this.loading = true
        const resDetail = await planetApi.getOrder(order.id)
        window.open(resDetail.data._links.results[0].location, '_blank')
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
