const SOURCES = [
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/Landsat8L1G',
  //   },
  //   display_description: 'Landsat 8 standard terrain-corrected scenes in path/row framing',
  //   display_name: 'Landsat 8 Scene',
  //   id: 'Landsat8L1G',
  //   supported_asset_types: [
  //     'analytic_b1',
  //     'analytic_b10',
  //     'analytic_b11',
  //     'analytic_b2',
  //     'analytic_b3',
  //     'analytic_b4',
  //     'analytic_b5',
  //     'analytic_b6',
  //     'analytic_b7',
  //     'analytic_b8',
  //     'analytic_b9',
  //     'analytic_bqa',
  //     'metadata_txt',
  //     'visual',
  //   ],
  // },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/MOD09GA',
  //   },
  //   display_description: 'Terra MODIS Reflectance 500m, 1000m',
  //   display_name: 'MOD09GA',
  //   id: 'MOD09GA',
  //   supported_asset_types: [
  //     'analytic_gflags',
  //     'analytic_granule_pnt',
  //     'analytic_iobs_res',
  //     'analytic_num_observations_1km',
  //     'analytic_num_observations_500m',
  //     'analytic_obscov_500m',
  //     'analytic_orbit_pnt',
  //     'analytic_q_scan',
  //     'analytic_qc_500m',
  //     'analytic_range',
  //     'analytic_sensor_azimuth',
  //     'analytic_sensor_zenith',
  //     'analytic_solar_azimuth',
  //     'analytic_solar_zenith',
  //     'analytic_state_1km',
  //     'analytic_sur_refl_b01',
  //     'analytic_sur_refl_b02',
  //     'analytic_sur_refl_b03',
  //     'analytic_sur_refl_b04',
  //     'analytic_sur_refl_b05',
  //     'analytic_sur_refl_b06',
  //     'analytic_sur_refl_b07',
  //   ],
  // },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/MOD09GQ',
  //   },
  //   display_description: 'Terra MODIS Reflectance 250m',
  //   display_name: 'MOD09GQ',
  //   id: 'MOD09GQ',
  //   supported_asset_types: [
  //     'analytic_granule_pnt',
  //     'analytic_iobs_res',
  //     'analytic_num_observations',
  //     'analytic_obscov',
  //     'analytic_orbit_pnt',
  //     'analytic_qc_250m',
  //     'analytic_sur_refl_b01',
  //     'analytic_sur_refl_b02',
  //   ],
  // },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/MYD09GA',
  //   },
  //   display_description: 'Aqua MODIS Reflectance 500m, 1000m',
  //   display_name: 'MYD09GA',
  //   id: 'MYD09GA',
  //   supported_asset_types: [
  //     'analytic_gflags',
  //     'analytic_granule_pnt',
  //     'analytic_iobs_res',
  //     'analytic_num_observations_1km',
  //     'analytic_num_observations_500m',
  //     'analytic_obscov_500m',
  //     'analytic_orbit_pnt',
  //     'analytic_q_scan',
  //     'analytic_qc_500m',
  //     'analytic_range',
  //     'analytic_sensor_azimuth',
  //     'analytic_sensor_zenith',
  //     'analytic_solar_azimuth',
  //     'analytic_solar_zenith',
  //     'analytic_state_1km',
  //     'analytic_sur_refl_b01',
  //     'analytic_sur_refl_b02',
  //     'analytic_sur_refl_b03',
  //     'analytic_sur_refl_b04',
  //     'analytic_sur_refl_b05',
  //     'analytic_sur_refl_b06',
  //     'analytic_sur_refl_b07',
  //   ],
  // },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/MYD09GQ',
  //   },
  //   display_description: 'Aqua MODIS Reflectance 250m',
  //   display_name: 'MYD09GQ',
  //   id: 'MYD09GQ',
  //   supported_asset_types: [
  //     'analytic_granule_pnt',
  //     'analytic_iobs_res',
  //     'analytic_num_observations',
  //     'analytic_obscov',
  //     'analytic_orbit_pnt',
  //     'analytic_qc_250m',
  //     'analytic_sur_refl_b01',
  //     'analytic_sur_refl_b02',
  //   ],
  // },
  {
    _links: {
      _self: 'https://api.planet.com/data/v1/item-types/PSOrthoTile',
    },
    display_description: 'PlanetScope orthorectified 4-band imagery as 25km x 25km UTM tiles',
    display_name: 'PlanetScope Ortho Tile',
    id: 'PSOrthoTile',
    supported_asset_types: [
      'analytic',
      'analytic_5b',
      'analytic_5b_xml',
      'analytic_dn',
      'analytic_dn_xml',
      'analytic_sr',
      'analytic_xml',
      'udm',
      'udm2',
      'visual',
      'visual_xml',
    ],
  },
  {
    _links: {
      _self: 'https://api.planet.com/data/v1/item-types/PSScene',
    },
    display_description: '8-band PlanetScope imagery that is framed as captured.',
    display_name: 'PlanetScope Scene',
    id: 'PSScene',
    supported_asset_types: [
      'basic_analytic_4b',
      'basic_analytic_8b',
      'basic_analytic_8b_xml',
      'basic_analytic_4b_rpc',
      'basic_analytic_4b_xml',
      'basic_udm2',
      'ortho_analytic_3b',
      'ortho_analytic_3b_xml',
      'ortho_analytic_4b',
      'ortho_analytic_4b_sr',
      'ortho_analytic_4b_xml',
      'ortho_analytic_8b',
      'ortho_analytic_8b_sr',
      'ortho_analytic_8b_xml',
      'ortho_udm2',
      'ortho_visual',
    ],
  },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/REOrthoTile',
  //   },
  //   display_description: 'RapidEye orthorectified 5-band imagery as 25km x 25km UTM tiles',
  //   display_name: 'RapidEye Ortho Tile',
  //   id: 'REOrthoTile',
  //   supported_asset_types: ['analytic', 'analytic_sr', 'analytic_xml', 'udm', 'visual', 'visual_xml'],
  // },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/REScene',
  //   },
  //   display_description: 'RapidEye basic 5-band imagery with strip-based framing',
  //   display_name: 'RapidEye Basic Scene',
  //   id: 'REScene',
  //   supported_asset_types: [
  //     'basic_analytic_b1',
  //     'basic_analytic_b1_nitf',
  //     'basic_analytic_b2',
  //     'basic_analytic_b2_nitf',
  //     'basic_analytic_b3',
  //     'basic_analytic_b3_nitf',
  //     'basic_analytic_b4',
  //     'basic_analytic_b4_nitf',
  //     'basic_analytic_b5',
  //     'basic_analytic_b5_nitf',
  //     'basic_analytic_rpc',
  //     'basic_analytic_sci',
  //     'basic_analytic_xml',
  //     'basic_analytic_xml_nitf',
  //     'basic_udm',
  //     'browse',
  //   ],
  // },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/Sentinel1',
  //   },
  //   display_description: 'Sentinel 1 GRD Synthetic Aperture Radar Data',
  //   display_name: 'Sentinel-1 GRD Scene',
  //   id: 'Sentinel1',
  //   supported_asset_types: ['ortho_analytic_hh', 'ortho_analytic_hv', 'ortho_analytic_vh', 'ortho_analytic_vv'],
  // },
  // {
  //   _links: {
  //     _self: 'https://api.planet.com/data/v1/item-types/Sentinel2L1C',
  //   },
  //   display_description:
  //     'Sentinel-2 level 1C top-of-atmosphere reflectance 100 x 100 km tiles in cartographic geometry',
  //   display_name: 'Sentinel-2 Tile',
  //   id: 'Sentinel2L1C',
  //   supported_asset_types: [
  //     'analytic_b1',
  //     'analytic_b10',
  //     'analytic_b11',
  //     'analytic_b12',
  //     'analytic_b2',
  //     'analytic_b3',
  //     'analytic_b4',
  //     'analytic_b5',
  //     'analytic_b6',
  //     'analytic_b7',
  //     'analytic_b8',
  //     'analytic_b8a',
  //     'analytic_b9',
  //     'metadata_aux',
  //     'visual',
  //   ],
  // },
  {
    _links: {
      _self: 'https://api.planet.com/data/v1/item-types/SkySatCollect',
    },
    display_description: 'SkySat imagery with strip-based framing',
    display_name: 'SkySat Collect',
    id: 'SkySatCollect',
    supported_asset_types: [
      'basic_l1a_all_frames',
      'ortho_analytic',
      'ortho_analytic_dn',
      'ortho_analytic_sr',
      'ortho_analytic_udm',
      'ortho_analytic_udm2',
      'ortho_panchromatic',
      'ortho_panchromatic_dn',
      'ortho_panchromatic_udm',
      'ortho_panchromatic_udm2',
      'ortho_pansharpened',
      'ortho_pansharpened_udm',
      'ortho_pansharpened_udm2',
      'ortho_visual',
    ],
  },
  {
    _links: {
      _self: 'https://api.planet.com/data/v1/item-types/SkySatScene',
    },
    display_description: 'SkySat imagery that is framed as captured',
    display_name: 'SkySat Scene',
    id: 'SkySatScene',
    supported_asset_types: [
      'basic_analytic',
      'basic_analytic_dn',
      'basic_analytic_dn_rpc',
      'basic_analytic_rpc',
      'basic_analytic_udm',
      'basic_analytic_udm2',
      'basic_l1a_panchromatic_dn',
      'basic_l1a_panchromatic_dn_rpc',
      'basic_panchromatic',
      'basic_panchromatic_dn',
      'basic_panchromatic_dn_rpc',
      'basic_panchromatic_rpc',
      'basic_panchromatic_udm2',
      'ortho_analytic',
      'ortho_analytic_dn',
      'ortho_analytic_sr',
      'ortho_analytic_udm',
      'ortho_analytic_udm2',
      'ortho_panchromatic',
      'ortho_panchromatic_dn',
      'ortho_panchromatic_udm',
      'ortho_panchromatic_udm2',
      'ortho_pansharpened',
      'ortho_pansharpened_udm',
      'ortho_pansharpened_udm2',
      'ortho_visual',
    ],
  },
  {
    _links: {
      _self: 'https://api.planet.com/data/v1/item-types/SkySatVideo',
    },
    display_description: 'SkySat video product',
    display_name: 'SkySat Video',
    id: 'SkySatVideo',
    supported_asset_types: ['video_file', 'video_frames', 'video_metadata'],
  },
]
export default SOURCES
